import React from "react"

import Layout from "../components/layout"

const Success = () => (
  <Layout>
    <div className="container pad">
      <h1 className="xd-h1 mb-4">Submission received</h1>
      <h3>Thanks for reaching out! We will review your submission and get back to you as soon as possible.</h3>
    </div>
  </Layout>
)

export default Success
