import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import logo from "../../images/apple-logo.png"
import plank from "../../images/plank.jpg"

import "./header.scss"

function Header({ siteTitle }) {
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <div className="bg-secondary gap-header bg-cover" style={{ backgroundImage: `url("${plank}")` }}>
      <div className="container">
        <nav className="flex flex-wrap items-center justify-between py-2">
          <div className="flex items-center flex-shrink-0 mr-8 text-white">
            <span className="text-xl font-semibold tracking-tight w-56 mb-4">
            <Link to={`/`} ><img src={logo} alt="XD Logo" /></Link>
            </span>
          </div>
          <div className="block lg:hidden">
            <div role="presentation" onClick={() => toggleExpansion(!isExpanded)} onKeyDown={() => toggleExpansion(!isExpanded)} className="flex items-center px-3 py-2 cursor-pointer" >
              <span className="material-icons text-white">menu</span>
            </div>
          </div>
          <div className={`${ isExpanded ? `block` : `hidden` } w-full block flex-grow lg:flex lg:items-center lg:w-auto transition-all duration-500 mb-4 md:mb-0`} >
            <div className="text-lg lg:flex-grow space-x">
              <Link to={`/`} activeStyle={{ textDecoration: 'underline' }} className="block mt-4 mr-8 text-white lg:inline-block lg:mt-0 hover:text-white" >Home</Link>
              <Link to={`/products`} activeStyle={{ textDecoration: 'underline' }} className="block mt-4 mr-8 text-white lg:inline-block lg:mt-0 hover:text-white" >Products</Link>
              <Link to={`/service-area`} activeStyle={{ textDecoration: 'underline' }} className="block mt-4 mr-8 text-white lg:inline-block lg:mt-0 hover:text-white" >Service Area</Link>
              <Link to={`/about`} activeStyle={{ textDecoration: 'underline' }} className="block mt-4 mr-8 text-white lg:inline-block lg:mt-0 hover:text-white" >About Us</Link>
            </div>
            <div className="text-base">
              <Link to={`/contact`} activeStyle={{ textDecoration: 'underline' }} className="block mt-4 ml-auto text-white lg:inline-block lg:mt-0 hover:text-white" >Contact Us</Link>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
